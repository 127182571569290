@import '@/styles/_variables';

.container {
  width: 100%;
  min-height: 200px;
  &__title {
    width: 100%;
    padding-left: 28px;
    padding-right: 28px;
    margin: 0 auto;
    font-family: $font-cambon;
    font-weight: 700;
    color: $color-navy;
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 24px;
    line-height: 30px;
  }
}

@media (min-width: $md) {
  .container {
    margin: 0 auto;
    width: 100%;
    min-height: 220px;
    display: grid;
    grid-template-columns: 73% 27%;
    &__title {
      width: inherit;
      margin: 0;
      font-size: 30px;
      line-height: 36px;
      padding: 37px 28px 40px;
    }
  }
}
@media (min-width: $lg) {
  .container {
    min-height: 270px;
    &__title {
      font-size: 30px;
      line-height: 36px;
      padding: 77px 165px 40px 78px;
    }
  }
}
@media (min-width: $xl) {
  .container {
    min-height: 320px;
    &__title {
      font-size: 42px;
      line-height: 46px;
      padding: 97px 90px 40px 78px;
    }
  }
}

@media (min-width: $xxl) {
  .container {
    min-height: 360px;
    &__title {
      font-size: 42px;
      line-height: 46px;
      padding: 97px 185px 40px 78px;
    }
  }
}

@media (min-width: $xxxl) {
  .container {
    &__title {
      padding-left: 178px;
    }
  }
}

@media (min-width: $fhd) {
  .container {
    &__title {
      padding-left: 15vw;
    }
  }
}
