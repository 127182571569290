@import '@/styles/_variables';

.container {
  display: none;
}

@media (min-width: $md) {
  .container {
    display: block;
    width: 100%;
    min-height: 210px;
    background-color: $color-navy;
    &__title {
      font-size: 20px;
      line-height: 24px;
      color: $color-white;
      font-weight: $font-weight-semibold;
      font-family: $font-gt-display;
    }
  }
  .wrapLogo {
    display: grid;
    grid-template-columns: min-content min-content;
    column-gap: 15px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;

    svg {
      width: 63px;
      height: auto;
    }
  }
  .wrapLink {
    display: block;
    padding-top: 15px;
    margin-left: 20px;
    margin-right: 20px;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 5px;
    z-index: 1;
    position: relative;
  }
  .wrapLink p {
    font-size: 16px;
    line-height: 20px;
    color: $color-white;
    font-weight: $font-weight-medium;
    font-family: $font-gt-display;
  }
  .wrapLink div {
    justify-self: end;
  }
}

@media (min-width: $lg) {
  .container {
    &__title {
      font-size: 22px;
      line-height: 26px;
    }
  }
  .wrapLogo {
    grid-template-columns: 1fr;
    row-gap: 15px;
    padding-left: 23px;
    padding-right: 23px;
    padding-top: 30px;
  }
  .wrapLink {
    padding-top: 25px;
    margin-left: 23px;
    margin-right: 23px;
    row-gap: 15px;
    padding-bottom: 26px;
  }
}

@media (min-width: $xl) {
  .container {
    &__title {
      font-size: 26px;
      line-height: 28px;
    }
  }
  .wrapLogo {
    grid-template-columns: 1fr;
    row-gap: 22px;
    padding-left: 44px;
    padding-right: 44px;
    padding-top: 30px;
  }
  .wrapLink {
    padding-top: 15px;
    margin-left: 44px;
    margin-right: 44px;

    row-gap: 25px;
    padding-bottom: 26px;
  }
  .wrapLink p {
    font-size: 20px;
    line-height: 24px;
  }
}

@media (min-width: $xxl) {
  .wrapLogo {
    row-gap: 22px;
    padding-top: 40px;
  }
  .wrapLink {
    padding-top: 25px;

    row-gap: 32px;
    padding-bottom: 26px;
  }
}

.pastel {
  .container__title {
    color: $color-navy;
  }

  .wrapLink p {
    color: $color-dark2-grey;
  }
}
