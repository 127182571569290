.size {
  width: inherit;
  height: inherit;
}

.cls1 {
  fill: none;
}

.cls2 {
  fill: #fefefe;
}

.cls3 {
  fill: #d5d6d8;
}

.cls4 {
  fill: #fdfefd;
}

.cls5 {
  fill: #fdfdfd;
}

.cls6 {
  fill: #fcfdfd;
}

.cls7 {
  fill: #fdfefe;
}

.cls8 {
  fill: #fdfdfe;
}

.cls9 {
  fill: #f0f6f7;
}

.cls10 {
  fill: #f6fbfb;
}

.cls11 {
  fill: #f7fbfb;
}

.cls12 {
  fill: #f6f9fa;
}

.cls13 {
  fill: #f4f9fa;
}

.cls14 {
  fill: #e6f1f2;
}

.cls15 {
  fill: #d9ecef;
}

.cls16 {
  fill: #dbecee;
}

.cls17 {
  fill: #e6f0ef;
}

.cls18 {
  fill: #d8ebf0;
}

.cls19 {
  fill: #eff4f4;
}

.cls20 {
  fill: #f4f6f5;
}

.cls21 {
  fill: #daeaee;
}

.cls22 {
  fill: #ddecf0;
}
