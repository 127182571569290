@import '../../styles/_variables';

.onHeader {
  padding: 82px 0 30px;

  @media (min-width: $lg) {
    padding-top: 124px;
    padding-bottom: 40px;
  }
}

.list {
  margin: 0;
  list-style: none;
  display: block;
  width: 100%;
  padding-right: 28px;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;

  &__item {
    color: $color-white;
    opacity: 0.7;
    font-family: $font-gt-display;
    font-size: 14px;
    line-height: 1.3;
  }
}

.alink {
  text-decoration: underline;
  text-underline-offset: 4px;
}

.separator {
  margin: 0 8px;
}

.listNews {
  width: 88%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(7, max-content);

  &__item {
    font-family: $font-gt-display;
    font-size: 14px;
    line-height: 1.3;
  }
}

@media (min-width: $lg) {
  .list {
    padding-left: 0;
    padding-right: 0;
    &__item {
      font-size: 18px;
    }
  }
  .listNews {
    width: 73%;
    padding: 0;
    &__item {
      font-size: 18px;
    }
  }
}
