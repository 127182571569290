@import '@/styles/_variables';

.container {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 55px;
  display: grid;
  grid-template-rows: repeat(1, min-content);
  row-gap: 28px;

  .titleFirst,
  .titleSecond {
    width: inherit;
    margin: 0 auto;
    font-family: $font-cambon;
    font-weight: 700;
    color: $color-white;
    font-size: 24px;
    line-height: 30px;
    padding: 0 17px 0 28px;
  }

  &__titleSecond {
    padding-top: 0;
  }
}

.wrapIcon {
  padding-left: 28px;
  padding-bottom: 25px;
  @media (min-width: $md) {
  }
  padding-bottom: 20px;
}

.list {
  margin: 0;
  list-style: none;
  padding-left: 28px;
  display: grid;
  grid-template-columns: repeat(7, max-content);

  &__item {
    color: $color-white;
    opacity: 0.7;
    font-family: $font-gt-display;
    font-size: 14px;
    line-height: 1;
    border-bottom: 1px solid #ffffff93;

    &:last-child span {
      display: none;
    }
  }

  &__item span {
    margin-right: 4px;
    margin-left: 4px;
  }
}

.wrap {
  padding-left: 28px;
  padding-bottom: 20px;

  @media (min-width: $md) {
    padding-bottom: 0;
  }
}

.content {
  @media (min-width: $md) {
    padding-top: 20px;
  }
}

@media (min-width: $md) {
  .containerCorporate {
    padding: 0;
    grid-template-columns: 72% 28%;
  }
}

@media (min-width: $md) {
  .container {
    padding: 0;
    row-gap: 20px;
    position: relative;

    .content {
      padding-top: 36px;
      padding-bottom: 90px;
      padding-right: 78px;
    }

    .titleFirst,
    .titleSecond {
      margin: 0;
      font-size: 32px;
      line-height: 38px;
      padding: 40px 0 0 28px;
    }

    &__titleSecond {
      padding-top: 0;
    }
  }

  .list {
    padding: 0;

    &__item {
      font-size: 18px;
    }
  }

  .wrapIcon {
    padding: 0;
    position: absolute;
    top: 28px;
    right: 65px;
    @media (min-width: $xxxl) {
      right: 178px;
    }
    @media (min-width: $fhd) {
      right: 15vw;
    }
  }
}

@media (min-width: $lg) {
  .container {
    padding-left: 78px;

    .titleFirst,
    .titleSecond {
      padding-left: 0;
    }
  }

  .wrap {
    padding: 0;
  }
}

@media (min-width: $xxxl) {
  .container {
    padding-left: 178px;

    .content {
      padding-right: 178px;
    }
  }

  .wrapIcon {
    right: 178px;
  }
}

@media (min-width: $fhd) {
  .container {
    padding-left: 15vw;

    .content {
      padding-right: 15vw;
    }
  }

  .wrapIcon {
    right: 15vw;
  }
}
.contentWithIcon{
  display: grid;
  grid-template-columns: 1fr;
svg{
  margin: 0 auto;
}
  row-gap: 20px;
  @media (min-width: $md) {
    grid-template-columns: min-content 1fr;
    column-gap: 50px;
    margin-left: 28px;
    margin-top: 20px;

    svg{
      margin-left: 80px;
      align-self: center;
    }
  }

  @media (min-width: $lg) {
    svg{
      margin-left: 0;
      margin-top: 20px;
    }
  }
}

.withOutIcon{}
