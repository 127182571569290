@import '@/styles/_variables';

.container {
  margin-top: 5px;
  width: 100%;
  min-height: 210px;
  background-color: $color-navy;
  &__title {
    font-size: 24px;
    line-height: 28px;
    color: $color-white;
    font-weight: $font-weight-semibold;
    font-family: $font-gt-display;
  }
}
.wrapLogo {
  display: grid;
  grid-template-columns: min-content min-content;
  column-gap: 20px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 29px;
}

.logoSize {
  width: 42px;
  height: 54px;
}

@media (min-width: $lg) {
  .logoSize {
    width: 63px;
    height: 80px;
  }
}

.wrapLink {
  display: block;
  padding-top: 20px;
  margin-left: 87px;
  margin-right: 87px;
  display: grid;
  grid-template-columns: 190px min-content;
  column-gap: 15px;
  z-index: 1;
  position: relative;
}

.wrapLink p {
  font-size: 16px;
  line-height: 20px;
  color: $color-white;
  font-weight: $font-weight-medium;
  font-family: $font-gt-display;
}

@media (min-width: $md) {
  .container {
    display: none;
  }
}
