.size {
  width: inherit;
  height: inherit;
}

.cls1 {
  fill: #fefefe;
}

.cls2 {
  fill: #d5d6d8;
}

.cls3 {
  fill: #e6e9ea;
}

.cls4 {
  fill: #e4e6e7;
}

.cls5 {
  fill: #e4e8e7;
}

.cls6 {
  fill: #dee7ea;
}

.cls7 {
  fill: #cedfe2;
}

.cls8 {
  fill: #e4eaeb;
}

.cls9 {
  fill: #e4e5e7;
}

.cls10 {
  fill: #e2e9ea;
}
