.size {
  width: inherit;
  height: inherit;
}

.cls1 {
  fill: #fefefe;
}

.cls2 {
  fill: #d5d6d8;
}

.cls3 {
  fill: #f4e2db;
}

.cls4 {
  fill: #f2e2dc;
}

.cls5 {
  fill: #f5dfd8;
}

.cls6 {
  fill: #f3e3dd;
}

.cls7 {
  fill: #f4e3db;
}

.cls8 {
  fill: #f4dfd8;
}

.cls9 {
  fill: #f4e3da;
}

.cls10 {
  fill: #f4e0d8;
}

.cls11 {
  fill: #f3e3dc;
}

.cls12 {
  fill: #f4e0d7;
}

.cls13 {
  fill: #f3e2dc;
}

.cls14 {
  fill: #f1e9e5;
}

.cls15 {
  fill: #f3e8e4;
}
