.size {
  width: inherit;
  height: inherit;
}

.cls1 {
  fill: #fefefe;
}

.cls2 {
  fill: #d5d6d8;
}

.cls3 {
  fill: #f4f4f5;
}

.cls4 {
  fill: #dcdfe2;
}

.cls5 {
  fill: #d3d7da;
}

.cls6 {
  fill: #d7dadc;
}

.cls7 {
  fill: #dde0e2;
}

.cls8 {
  fill: #d9dbdf;
}

.cls9 {
  fill: #dee0e2;
}

.cls10 {
  fill: #d9dcdf;
}

.cls11 {
  fill: #d7dadd;
}

.cls12 {
  fill: #f2f3f2;
}

.cls13 {
  fill: #f6f7f7;
}

.cls14 {
  fill: #f6f8f9;
}

.cls15 {
  fill: #f2f2f2;
}
