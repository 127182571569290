.size {
  width: inherit;
  height: inherit;
}

.cls1{fill:#fefefe;}
.cls2{fill:#d5d6d8;}
.cls3{fill:#f4f4f4;}
.cls4{fill:#e6edec;}
.cls5{fill:#f4f4f3;}
.cls6{fill:#e4edeb;}
.cls7{fill:#e6edeb;}
